#companyHeader {
  display: flex;
  padding: 0 0.8em 1.1em;
}

#companyName {
  align-self: center;
  padding-left: 0.86em;
}
#companyLogo {
  height: 7em;
  width: auto;
}
.consent {
  padding: 0 2.07em 0 1.21em;
  line-height: 1.57;
  overflow: scroll;
}
