.whiteTitle {
  box-sizing: border-box;
  width: 100%;
  padding: 0.36em 0.67em 1.11em;
}

.whiteTitleSep {
  height: 2px;
  display: flex;
  margin-top: 0.3em;
}

.whiteTitleSepStart {
  width: 24%;
  background-color: red;
}

.whiteTitleSepEnd {
  width: 76%;
  background-color: lightgrey;
}

body {
  background-color: white;
}
