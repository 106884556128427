* {
  margin-block-start: 0;
}

html {
  color: #272727;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 2.2vh;
}

html,
body {
  padding: 0;
}

html,
body,
#root {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
}

#root {
  padding: 0.71em 0 0.57em;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.header {
  font-weight: 300;
  font-size: 1.29em;
  line-height: 1.56;
}

.subHeader {
  font-weight: 300;
  font-size: 1.14em;
}

.flex1 {
  flex: 1;
}

.flex0 {
  min-height: min-content;
}

button.primary,
button.secondary {
  margin: 0.5em auto 0;
  box-sizing: border-box;
  font-size: 1.07em;
  width: 88%;
  padding: 0.58em 0;
  outline: none;
}

button.primary {
  border-radius: 3em;
  background-color: #4891e3;
  color: white;
}

button.secondary {
  background-color: white;
  color: #4a90e2;
  border-width: 0;
}

button:disabled {
  background-color: #9b9b9b;
}

.footer {
  margin-top: 0.5em;
  text-align: center;
  font-size: 65%;
}

@media only screen and (orientation: landscape) and (min-width: 1000px) {
  body {
    width: 56vh;
    border-radius: 1em;
    margin: 0 auto;
  }

  html {
    background-color: dimgrey;
  }
}
