.instImgLong {
  margin-top: 0.4em;
  width: 2.5em;
  align-self: flex-start;
}

.instImg {
  width: 2em;
  align-self: flex-start;
}

.instCustom {
  font-size: 0.86em;
  font-style: italic;
  color: white;
  padding: 0.75em 0;
}

#preInstructions {
  background-color: #f443369e;
  text-align: center;
  margin-bottom: 1.43em;
}

.instructionsLong {
  margin: 0 1.75em 0 1.4em;
  display: flex;
  align-items: center;
}

.instructionsLong span {
  margin-left: 1.1em;
}

.instructions {
  margin: 0 1.75em 0 1.57em;
  display: flex;
  align-items: center;
}

.instructions span {
  margin-left: 1.43em;
  display: inline-block;
}

.instructionsSep {
  box-sizing: border-box;
  margin: 1.14em 1.21em 1.14em 1.21em;
  height: 1px;
  width: calc(100% - 2.42em);
  background-color: rgba(155, 155, 155, 0.26);
}

.instLabel {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
}
