.blackTitle {
  display: flex;
  margin-bottom: 0.2em;
}

.btnBack {
  width: 1.71em;
  height: 1.71em;
  margin: 0 1em 0;
}

.blackTitleSep {
  display: flex;
  width: 100%;
  height: 2px;
}

.blackTitleSepStart {
  background-color: #608d2e;
}

.blackOval {
  position: relative;
  overflow: hidden;
}
.vidMask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.videoFeed {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.videoFeed {
  z-index: 1;
  background: transparent;
  object-fit: cover;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.vidMask {
  z-index: 2;
  background-size: 100% 100%;
}

#videoTimer {
  z-index: 3;
  left: 0;
  right: 0;
  position: absolute;
  top: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recordCircle {
  width: 1.14em;
  height: 1.14em;
  background-color: #c43b2c;
  border-radius: 50%;
  margin-right: 0.29em;
}

.actionContainer {
  padding-top: 1.5em;
  max-height: 25%;
  text-align: center;
  margin: 0 auto;
}

.actionInstruction {
  line-height: 1.56;
  margin-bottom: 0em;
  font-size: 1em;
}

.actionNumbers {
  font-size: 2.71em;
  font-weight: bold;
}

.actionText {
  line-height: 1.5;
  padding: 0 0.71em;
  overflow-y: auto;
  height: 80%;
  word-break: break-word;
}
.showDocument {
  line-height: 1.5;
  padding: 0 0.71em;
}
/* @media only screen and (max-width: 600px) {
  body {
    background-color: lightblue;
  }
} */

.arrow {
  text-align: center;
  margin: 2px 0;
  padding: 10px 5px 0 5px;
}

.arrow-container {
  display: inline-flex;
  margin-bottom: -10px;
  overflow-x: hidden;
  width: 88%;
  height: 5%;
  margin-left: auto;
  margin-right: auto;
}

.arrow-text {
  margin-top: auto;
  margin-left: auto;
  margin-bottom: auto;
  color:#9b9b9b
}

.bounce {
  -moz-animation: bounce 1s infinite;
  -webkit-animation: bounce 1s infinite;
  animation: bounce 1s infinite;
}

.bounce img{
  transform: rotate(-90deg);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) rotate(90deg);
  }
  40% {
    transform: translateY(-10px) rotate(90deg);
  }
  60% {
    transform: translateY(-10px) rotate(90deg);
  }
}
