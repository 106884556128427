.smallScreen {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 2.07em;
}

.img60 {
  width: 4.29em;
  height: 4.29em;
}

.img68 {
  width: 4.86em;
  height: 4.86em;
}

.img110 {
  width: 7.86em;
  height: 7.86em;
}

.img60,
.img68,
.img110 {
  margin-bottom: 1.43em;
}

.header28 {
  font-weight: 400;
  font-size: 2em;
  margin-bottom: 1em;
}

.header24 {
  font-weight: 400;
  font-size: 1.71em;
  margin-bottom: 0.67em;
}

a {
  color: #4a90e2;
  text-decoration: none;
}

.browserLink {
  margin-top: 1em;
  display: flex;
  align-items: center;
}

.browserLink img {
  width: 1.43em;
  height: 1.43em;
  margin-right: 0.79em;
}

.doneImg {
  margin-bottom: 2.43em;
}

.errRecaptureScreen {
  padding: 0 0.8em;
}

.errTimeoutScreen {
  padding: 0 1.4em;
}
.errPermissionDenied {
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.errPermissionDeniedTitle {
  margin-bottom: 0.35em;
  font-size: 1.5rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0em;
  margin: 0;
}
.steps {
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin: 0 0 0.3em 0;
  text-align: left;
  width: 100%;
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
